import { Box, Flex, Text } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useSession } from 'next-auth/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import AddToCartUnlogged from '@/components/AddToCartUnlogged';
import { BadgeNew } from '@/components/Badges/BadgeNew';
import CurrentPriceUnlogged from '@/components/Card/CurrentPriceUnlogged';
import { FavouriteButton } from '@/components/FavouriteButton/FavouriteButton';
import { H3 } from '@/components/Heading';
import ProductImage from '@/components/Image/ProductImage';
import { ProductPrice, ProductUnitPrice } from '@/components/Product';
import { ProductCode } from '@/components/Product/ProductCode/ProductCode';
import { ProductLowestPrice } from '@/components/Product/ProductLowestPrice/ProductLowestPrice';
import ProductQuantityHandlerUnlogged from '@/components/ShoppingCart/ProductQuantityHandlerUnlogged';
import { UnavailableProductButton } from '@/components/UI/Buttons/UnavailableProductButton';
import NavLink from '@/components/UI/Links/NavLink';

import { ItemsPropsUnlogged, ProductCardProps } from '@/models/props/ProductCardProps';
import { AppService } from '@/services';
import { ProductUtil } from '@/utils';

export const ProductCardUnlogged = (props: ProductCardProps) => {
    const {
        categories,
        code,
        currency,
        discount,
        grossPrice,
        grossPriceBeforeDiscount,
        lowestPriceIn30Days,
        isNew,
        mainImage,
        netPrice,
        netPromoPrice,
        netUnitPrice,
        notAvailableMessage,
        points,
        productAvailableForSale,
        slug,
        title,
        unit,
        imageWrapperProps,
        detailsProps,
        imageProps,
        linkToProductPage = true,
        subscribed = false,
        favourite,
        showFavourite = true
    } = props;
    const session = useSession();
    const { cartQuantity, cartItemsUnlogged, fetchingData } = useShoppingCart();
    const [unloggedProduct, setUnloggedProduct] = useState<ItemsPropsUnlogged>();
    const [currQuantity, setCurrQuantity] = useState<number>();
    const [inCart, setInCart] = useState<boolean>(false);
    const productURL = ProductUtil.getProductUrl({
        code,
        slug,
        categories
    });
    const funcPullQuantity = (q: number) => {
        setCurrQuantity(q);
    };
    const obj = useMemo(
        () => ({
            grossPrice,
            cartQuantity,
            cartItemsUnlogged,
            session
        }),
        [grossPrice, cartItemsUnlogged, cartQuantity, session]
    );
    const intl = useIntl();

    useEffect(() => {
        const product = obj.cartItemsUnlogged?.find((i) => i.code === code);

        if (product) {
            setCurrQuantity(product.quantity);
            setInCart(true);
            setUnloggedProduct(product);

            return;
        } else {
            setInCart(false);
        }
    }, [code, currQuantity, obj]);

    const addToFavouritesDataLayer = useCallback(() => {
        const currency = AppService.getInstanceCurrency();
        const brand = intl.formatMessage({
            id: `data-layer.brand.${process.env.NEXT_PUBLIC_SELL_ZONE}`
        });
        const numberGrossPrice = Number(grossPrice?.replace(',', '.'));
        const numberGrossPriceBeforeDiscount = Number(grossPriceBeforeDiscount?.replace(',', '.'));
        const discount = Math.round(numberGrossPriceBeforeDiscount * 100 - numberGrossPrice * 100);
        const category = categories?.[0].name || '';

        window.dataLayer.push({ ecommerce: 'null' });
        window.dataLayer.push({
            event: 'add_to_wishlist',
            ecommerce: {
                currency: currency,
                value: numberGrossPrice,
                items: [
                    {
                        item_id: code,
                        item_name: title,
                        index: 1,
                        discount: discount / 100,
                        item_brand: brand,
                        item_category: category,
                        price: numberGrossPrice,
                        quantity: 1
                    }
                ]
            }
        });
    }, [categories, code, grossPrice, grossPriceBeforeDiscount, title, intl]);

    const addToCartDataLayer = useCallback(() => {
        const currency = AppService.getInstanceCurrency();
        const brand = intl.formatMessage({
            id: `data-layer.brand.${process.env.NEXT_PUBLIC_SELL_ZONE}`
        });
        const numberGrossPrice = Number(grossPrice?.replace(',', '.'));
        const numberGrossPriceBeforeDiscount = Number(grossPriceBeforeDiscount?.replace(',', '.'));
        const discount = Math.round(numberGrossPriceBeforeDiscount * 100 - numberGrossPrice * 100);
        const category = categories?.[0].name || '';

        window.dataLayer.push({ ecommerce: 'null' });
        window.dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
                currency: currency,
                value: numberGrossPrice,
                cart_button_type: 'Listing',
                items: [
                    {
                        item_id: code,
                        item_name: title,
                        index: 1,
                        discount: discount / 100,
                        item_brand: brand,
                        item_category: category,
                        price: numberGrossPrice,
                        quantity: 1
                    }
                ]
            }
        });
    }, [categories, code, grossPrice, grossPriceBeforeDiscount, title, intl]);

    return (
        <Flex rowGap={6} as="article" w="100%" h="100%" flexDirection="column" overflow="hidden">
            <Box pos="relative" w="100%">
                <Flex justifyContent="center" alignItems="center" {...imageWrapperProps}>
                    {linkToProductPage && grossPrice ? (
                        <NavLink href={productURL} position="relative" w="100%">
                            <ProductImage
                                mainImage={mainImage}
                                title={title}
                                width={imageProps?.width || 450}
                                height={imageProps?.height || 300}
                            />
                        </NavLink>
                    ) : (
                        <ProductImage
                            mainImage={mainImage}
                            title={title}
                            width={imageProps?.width || 450}
                            height={imageProps?.height || 300}
                        />
                    )}
                </Flex>
                {isNew && (
                    <>
                        <BadgeNew
                            boxProps={{
                                pos: 'absolute',
                                top: 4,
                                left: 4
                            }}
                        />
                    </>
                )}
                {showFavourite && (
                    <FavouriteButton
                        key={code}
                        checked={favourite}
                        productCode={code}
                        removeFromFavourite={props?.removeFromFavourite}
                        addToFavouriteCallback={addToFavouritesDataLayer}
                    />
                )}
            </Box>
            <Box display="flex" flexDirection="column" height="50%" {...detailsProps}>
                <Flex alignItems="center" justifyContent="space-between" mb={6}>
                    <ProductCode productId={code} />
                    {session.data?.user && points > 0 && (
                        <Text fontSize="sm" color="grey.700">
                            {points} <FormattedMessage id="pkt" />
                        </Text>
                    )}
                </Flex>
                <H3 size="h4" fontWeight="normal" mt={1} minH={10}>
                    {linkToProductPage && grossPrice ? (
                        <NavLink href={productURL}>{title}</NavLink>
                    ) : (
                        <Text>{title}</Text>
                    )}
                </H3>
                <Box position="relative" minH={75}>
                    <Box
                        opacity={unloggedProduct && inCart && productAvailableForSale ? 0 : 1}
                        pointerEvents={
                            unloggedProduct && inCart && productAvailableForSale ? 'none' : 'auto'
                        }
                    >
                        <ProductPrice
                            grossPrice={grossPrice}
                            grossPriceBeforeDiscount={grossPriceBeforeDiscount}
                            currency={currency}
                            productAvailableForSale={productAvailableForSale}
                            discount={discount}
                            code={code}
                            new={isNew}
                            notAvailableMessage={notAvailableMessage}
                        />
                        <ProductUnitPrice
                            grossPrice={grossPrice}
                            netPrice={netPrice}
                            netPromoPrice={netPromoPrice}
                            netUnitPrice={netUnitPrice}
                            unit={unit}
                            currency={currency}
                            productAvailableForSale={productAvailableForSale}
                        />
                        {productAvailableForSale && (
                            <ProductLowestPrice
                                discount={discount}
                                isNew={isNew}
                                lowestPriceIn30Days={lowestPriceIn30Days}
                                currency={currency}
                            />
                        )}
                    </Box>
                    {unloggedProduct && inCart && productAvailableForSale && (
                        <Box
                            pointerEvents={fetchingData ? 'none' : 'auto'}
                            opacity={fetchingData ? 0.5 : 1}
                        >
                            <CurrentPriceUnlogged
                                product={unloggedProduct}
                                currQuantity={currQuantity ?? 0}
                                currency={currency || ''}
                            />
                        </Box>
                    )}
                </Box>
                <Box mt="auto">
                    {productAvailableForSale && grossPrice ? (
                        currQuantity !== undefined && inCart ? (
                            <Box
                                pt={5}
                                width="100%"
                                pointerEvents={fetchingData ? 'none' : 'auto'}
                                opacity={fetchingData ? 0.5 : 1}
                            >
                                <ProductQuantityHandlerUnlogged
                                    setQuantity={funcPullQuantity}
                                    code={code}
                                    grossPrice={grossPrice}
                                    quantity={currQuantity}
                                    maxOrder={30}
                                    variant="xl"
                                    fetchingData={fetchingData}
                                    incraseProductCountCallback={addToCartDataLayer}
                                />
                            </Box>
                        ) : (
                            <Flex
                                justifyContent="end"
                                pt={5}
                                width="100%"
                                pointerEvents={fetchingData ? 'none' : 'auto'}
                                opacity={fetchingData ? 0.5 : 1}
                            >
                                <AddToCartUnlogged
                                    code={code}
                                    quantity={1}
                                    grossPrice={grossPrice}
                                    maxOrder={30}
                                    setQuantity={funcPullQuantity}
                                    addToCartCallback={addToCartDataLayer}
                                />
                            </Flex>
                        )
                    ) : (
                        session.data && (
                            <UnavailableProductButton code={code} subscribed={subscribed} />
                        )
                    )}
                </Box>
            </Box>
        </Flex>
    );
};
